<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('add_shoplist')">基本信息</span>
      <span @click="toTypeUrl('add_shoplist_js')">结算信息</span>
      <span id="fzcolor">配置信息</span>
    </div>
    <div class="header" v-else>
      <span @click="toUrl('add_shoplist', false)">基本信息</span>
      <span @click="toUrl('add_shoplist_js')">结算信息</span>
      <span id="fzcolor">配置信息</span>
      <span @click="toUrl('add_shoplist_syr')">受益人信息</span>
    </div>
    <div class="desc">
       <h5 class="jsxx">产品信息</h5>
    </div>
    <div class="input_con">
      <ul>
        <li>
          <span><i>*</i>支付类型</span>
          <div class="box">
            <el-checkbox v-model="productCode" label="wxpay"
              >微信支付</el-checkbox
            >
          </div>
          <div class="box">
            <el-checkbox v-model="productCode" label="alipay"
              >支付宝支付</el-checkbox
            >
          </div>
          <div class="box">
            <el-checkbox v-model="productCode" label="unionpay"
              >银联支付</el-checkbox
            >
          </div>
        </li>
        <li>
          <span><i>*</i>使用场景</span>
          <div class="box">
            <el-radio v-model="formData.useScene" label="OFFLINE"
              >线下</el-radio
            >
          </div>
          <div class="box">
            <el-radio v-model="formData.useScene" label="ONLINE">线上</el-radio>
          </div>
        </li>
      </ul>
    </div>

    <div class="btn" v-if="pageType == 'edit'">
      <el-button @click="toTypeUrl('add_shoplist_js')" class="back"
        >上一步</el-button
      >
      <el-button @click="toTypeUrl('add_shoplist_syr')" class="next" type="primary"
        >下一步</el-button
      >
    </div>
    <div class="btn" v-else>
      <el-button @click="toUrl('add_shoplist_js', false)" class="back"
        >上一步</el-button
      >
      <el-button @click="toUrl('add_shoplist_syr')" class="next" type="primary"
        >下一步</el-button
      >
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// import { addApi } from "@/api/user/shopList.js";
export default {
  data() {
    return {
      formData: {
        productCode: "",
        useScene: "OFFLINE",
      },
      productCode: [],
      pageType: "", //页面状态
      buttonFlag: false, //防止重复点击
    };
  },
  computed: {
    ...mapState({
      storesApiDetail: "user_storesApiDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.storesApiDetail) {
        if (this.storesApiDetail.config) {
          this.formData = this.storesApiDetail.config;
          this.productCode = this.formData.productCode
            ? this.formData.productCode.split(",")
            : [];
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setStoresApi: "user_setStoresApi",
    }),
    submit() {
      let productCode = JSON.parse(JSON.stringify(this.productCode));
      if (productCode.length <= 0) {
        this.$message.error("至少选择一种支付类型");
        return;
      }
      this.formData.productCode = productCode.join(",");
      let data = {
        storeDetail: this.storesApiDetail
          ? this.storesApiDetail.storeDetail
          : null,
        account: this.storesApiDetail ? this.storesApiDetail.account : null,
        config: JSON.parse(JSON.stringify(this.formData)),
      };
      this.setStoresApi(data);
      this.buttonFlag = true;
      addApi(this.storesApiDetail).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.setStoresApi(null);
          this.$router.push({
            name: "shoplist_mdlb",
          });
        }
          this.buttonFlag = false;

      });
    },
    // 跳转页面
    toUrl(name) {
      let productCode = JSON.parse(JSON.stringify(this.productCode));
      if (productCode.length <= 0 && name=='add_shoplist_syr') {
        this.$message.error("至少选择一种支付类型");
        return;
      }
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let productCode = JSON.parse(JSON.stringify(this.productCode));
      this.formData.productCode =
        productCode.length > 0 ? productCode.join(",") : "";
      let data = {
        storeDetail: this.storesApiDetail ? this.storesApiDetail.storeDetail : null,
        account: this.storesApiDetail ? this.storesApiDetail.account : null,
        config: this.formData,
        beneInfos:this.storesApiDetail ? this.storesApiDetail.beneInfos : null,
      };
      this.setStoresApi(data);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}


.input_con ul li span {
  position: relative;
  display: inline-block;
  width: 132px;
  text-align: right;
  padding-right: 0px;
  margin-right: 30px;
  padding-left: 90px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.input_con ul li span i {
  color: #48b8b6;
  padding-right: 3px;
}
.input_con ul li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.input_con ul li .box {
  width: 230px;
}
.input_con {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;

  padding-top: 30px;
  padding-bottom: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}
</style>
